<template>
    <v-dialog :fullscreen="$store.state.isMobile" max-width="410px" v-model="dialog">
        <div class="popup__content">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h5 class="popup__title">{{$t('settings["Редактировать номер"]')}} <br> {{phone_current}}</h5>
            <v-form class="form"
                ref="form"
                v-model="valid"
                @submit="validate"
            >
                <div class="field">
                    <div class="control">
                        <v-text-field class="input" solo outlined flat
                            ref="phone"
                            type="tel"
                            :label="$t('settings[\'Номер телефона\']')"
                            v-model="phone"
                            :rules="phoneRules"
                            validate-on-blur
                        ></v-text-field>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <v-btn class="btn btn_primary" color="primary" block depressed
                            type="submit"
                        >
                            {{$t('settings["Cохранить"]')}}
                        </v-btn>
                    </div>
                </div>
            </v-form>
        </div>
    </v-dialog>
</template>

<script>
import Inputmask from 'inputmask'

export default {
    props: {
        phone_current: String
    },
    data() {
        return {
            inputmask: null,
            valid: true,
            phone: '',
            phoneRules: [
                v => (!!v && this.is_complete) || 'Некорректный номер телефона',
            ],
            dialog: false,
            is_complete: true,
        }
    },
    methods: {
        open() {
            this.dialog = true;
            this.$nextTick(() => {
                this.inputmask = new Inputmask({
                    mask: '+9{0,}',
                    showMaskOnHover: false,
                });
                let input = this.$refs.phone.$el.querySelector('input');
                this.inputmask.mask(input);
            });
        },
        close() {
            this.dialog = false;
        },
        validate(e) {
            e.preventDefault();

            if (this.$refs.phone.$el.querySelector('input').inputmask.isComplete()) {
                this.is_complete = true;
            } else {
                this.is_complete = false;
            }
            if (this.$refs.form.validate()) {
                // this.$refs.PopupSuccess.open();
                this.$store.dispatch('saveProfile', {
                    phone: this.phone
                }).then(() => {
                    // this.is_complete = true;
                    this.close();
                })
            }


        }
    }
}
</script>

<style lang="scss">

</style>
