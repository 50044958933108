<template>
    <v-dialog :fullscreen="$store.state.isMobile" max-width="410px" v-model="dialog">
        <div class="popup__content">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h5 class="popup__title">{{$t('settings["Изменить пароль"]')}}</h5>
            <v-form class="form"
                ref="form"
                @submit="submit"
            >
                <div class="field">
                    <div class="control">
                        <v-text-field solo outlined flat
                            type="password"
                            autocomplete="new-password"
                            :label="$t('form[\'Текущий пароль\']')"
                            required
                            :rules="passwordRules"
                            v-model="old_password"
                            :error-messages="error"
                            :error="!!error"
                            validate-on-blur
                        ></v-text-field>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <v-text-field solo outlined flat
                            type="password"
                            autocomplete="new-password"
                            :label="$t('form[\'Новый пароль\']')"
                            required
                            :rules="passwordRules"
                            v-model="new_password"
                            validate-on-blur
                        ></v-text-field>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <v-text-field solo outlined flat
                            type="password"
                            autocomplete="new-password"
                            :label="$t('form[\'Повторите новый пароль\']')"
                            required
                            :rules="passwordConfirmRules"
                            v-model="password_confirm"
                            validate-on-blur
                        ></v-text-field>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <v-btn color="primary" block depressed type="submit">
                            {{$t('settings["Cохранить"]')}}
                        </v-btn>
                    </div>
                </div>
            </v-form>
        </div>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            old_password: null,
            new_password: null,
            password_confirm: null,
            error: '',
            passwordRules: [
                v => !!v || this.$t('form[\'Укажите пароль\']'),
                v => (v && v.length >= 6) || this.$t('form[\'Пароль должен содержать не менее 6 символов\']'),
                v => (v && v.length < 30) || this.$t('form[\'Пароль должен содержать не более 30 символов\']'),
            ],
            passwordConfirmRules: [
                v => !!v || this.$t('form[\'Укажите пароль\']'),
                v => v === this.new_password || this.$t('form[\'Пароли не совпадают\']'),
                v => (v && v.length >= 6) || this.$t('form[\'Пароль должен содержать не менее 6 символов\']'),
                v => (v && v.length < 30) || this.$t('form[\'Пароль должен содержать не более 30 символов\']'),
            ],
        }
    },
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        submit(e) {
            e.preventDefault();

            if (this.$refs.form.validate()) {
                this.$axios.post('edit-password', {
                    old_password: this.old_password,
                    new_password: this.new_password
                })
                    .then(response => {
                        this.close();
                        this.$emit('successPassword', true)
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.data === 'Invalid password') {
                                this.error = this.$t('Invalid password')
                            }
                        }
                    })
            }
        },
    }
}
</script>

<style lang="scss">

</style>
